<template>
  <div>
    <section class="container section__mb">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb
            class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
          >
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50px"
            src="../../assets/conteudos_ser_atingido_title_diamond.png"
            alt=""
          />
          <h3 class="ml-3" style="color: #E06919">
            O trabalho dos Atingidos
          </h3>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card
            borderColor="#426E51"
            title="Trabalho e renda"
            titleColor="#426E51"
            title_alignment="center"
          >
            <template v-slot:conteudo>
              <p>
                As famílias atingidas foram prejudicadas em múltiplas dimensões
                de suas vidas em consequência do rompimento da barragem de
                Fundão. Entre as perdas e danos declarados no processo de
                cadastramento realizado pela Cáritas, pode-se constatar como
                foram afetadas as relações de trabalho e renda, conforme os
                dados coletados. Agrupadas em categorias, foram organizadas 19
                ocupações<sup>1</sup> para compreender a situação relativa aos
                afazeres das atingidas e atingidos em Mariana-MG.
              </p>
              <p>
                Como pode ser observado no gráfico abaixo, trata-se de um grupo
                diversificado de situações ocupacionais, de inserções na vida
                social. Existe um número considerável de pessoas classificadas
                como estudantes, trabalhando com carteira assinada, mas
                igualmente significativo é o número de pessoas desempregadas,
                aquelas inseridas em relações informais de trabalho,
                aposentados(as), bem como pessoas, em geral mulheres, que
                dedicam parte significativa de seu tempo de vida ao trabalho
                doméstico, na forma de cuidados diversos com a família, com a
                casa, com a terra.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_01.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                O gráfico seguinte, por sua vez, ilustra um cruzamento da
                ocupação com o sexo da pessoa. Tendo em vista as ocupações mais
                declaradas, percebe-se que os homens estão muito mais presentes
                nos empregos com carteira assinada, e quase 10% a mais entre os
                aposentados. Quando se observam as relações informais de
                trabalho, os homens contabilizam 10% a mais entre os
                trabalhadores sem carteira assinada. Por outro lado, as pessoas
                que se declaram "do lar", trabalhadores domésticos com ou sem
                carteira assinada, e pensionistas, são praticamente em sua
                totalidade mulheres, que também são maioria entre estagiárias,
                militares e servidoras públicas.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_02.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card
            borderColor="#426E51"
            title="Sobre os impactos nas ocupações em decorrência do rompimento da barragem"
            titleColor="#426E51"
            title_alignment="center"
          >
            <template v-slot:conteudo>
              <p>
                Uma questão importante foi identificada nas respostas dos
                atingidos e atingidas no Cadastro de Perdas e Danos: 876 pessoas
                (43,3% do total) sinalizaram a perda de trabalho assalariado em
                decorrência do rompimento da barragem de Fundão. Entre os
                homens, a perda de trabalho em decorrência do desastre foi
                maior: 54,2%. Já entre as mulheres, a perda foi de 31,1%.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_03.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                A perda de trabalho também atingiu mais aqueles com idade entre
                30 e 59 anos: cerca de 51%. Jovens de 25 a 29 anos foram 47,2%,
                enquanto entre 18 a 24 anos a perda foi semelhante aos que têm
                60 anos ou mais: quase 37%.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_04.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Para avaliar a perda de trabalho, atingidas e atingidos foram
                questionados sobre seus ofícios antes do rompimento da barragem
                de Fundão. A mesma pessoa podia declarar possuir mais de um
                ofício, mas a ampla maioria dos atingidos que responderam a essa
                pergunta declarou possuir somente um ofício antes do rompimento,
                já que para a resposta “dois ofícios” houve somente 168 casos, o
                que representa aproximadamente 19,1% do total. Para quantidades
                maiores de ofícios, os valores são ainda menos representativos.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_05.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Tendo em vista portanto, o ofício 1 (pessoas que declararam
                somente 1 ofício), que é o caso da ampla maioria dos
                respondentes, o setor com maior representatividade é o de
                Serviços (206 respostas, 23,5% do total), seguido de Agricultura
                (198 respostas, 22,6%), em terceiro, Construção Civil (19,7%), e
                em quarto Mineração (com 156 respostas, 17,8%). Como pode ser
                observado no gráfico abaixo, o total de respostas é maior do que
                o número de pessoas porque essa pergunta sobre setor do ofício
                era de múltipla escolha, havendo casos em que foi citada mais de
                uma atividade.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_06.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Como a ampla maioria das pessoas disse possuir somente um ofício
                antes do rompimento da barragem, foram realizados levantamentos
                e cruzamentos para melhor qualificar como se deram essas perdas
                de trabalho e remuneração.
              </p>
              <p>
                No gráfico abaixo, considerando o primeiro ofício declarado,
                percebe-se que este foi fortemente afetado pelo rompimento da
                barragem, já que quase a totalidade das pessoas atingidas
                afirmou que houve diminuição da renda relacionada ao rompimento.
                Um percentual bastante significativo de respostas (564, ou 64,6%
                do total) apontava que foi necessária a mudança de ofício como
                decorrência do rompimento da barragem.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_07.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                A questão da mudança de ofício e da diminuição da renda teve
                padrões parecidos quando cruzada com o sexo dos respondentes.
                Entretanto, praticamente todos consideram que a perda foi em
                decorrência do desastre–crime.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_08.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>
    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_09.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Já quando se considera a variável idade e sua relação com as
                perdas de renda do ofício principal, mais da metade das
                respostas indica a necessidade de mudança de ofício, sendo que o
                grupo de 18 a 24 anos foi o mais afetado: 74,4%. E praticamente
                todos informam que as perdas de rendimentos foram em decorrência
                do rompimento da barragem de Fundão.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_10.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>
    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_11.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Quando se observam os impactos no trabalho e renda considerando
                o setor, agricultura, serviços, mineração, construção civil e
                pecuária foram os mais significativamente afetados, embora os
                impactos tenham sido em vários setores. Em todos houve a
                sinalização por parte dos respondentes de que a renda diminuiu
                em função do rompimento da barragem e seus desdobramentos, como
                pode ser observado nos dois últimos gráficos a seguir.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_12.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center flex-column">
        <v-col cols="12" class="d-flex justify-center align-center">
          <img
            src="../../assets/conteudos_ser_atingido_grafico_13.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Com os dados apresentados, objetivou-se ilustrar como a
                desestruturação das atividades econômicas é percebida pelas
                famílias atingidas cadastradas, diante do desastre–crime e seus
                desdobramentos, a exemplo da contaminação do solo e das águas, a
                partir do aspecto ocupacional. As declarações sinalizaram
                transtornos e desarranjo em relação às atividades econômicas,
                assim como ao sustento financeiro. Soma-se a isso a necessidade
                imposta em grande parte dos casos de ser alterado o tipo de
                ofício, além das outras mudanças que ocorreram nas vidas das
                pessoas. O trabalho, que antes acontecia nos territórios de
                origem, foi forçosamente interrompido, levando as pessoas a um
                estilo de vida bem diferente do que desejavam ter.
              </p>
              <p style="text-align: center">
                <strong
                  >Autora: Pedro Paulo Barros Gonçalves<sup>2</sup>, Túlio Silva
                  de Paula<sup>3</sup></strong
                >
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Para uma melhor compreensão desses dados, ressalta-se
              que as respostas às questões de ocupação, profissão, serviço são
              do tipo múltipla escolha. A mesma pessoa poderia apontar mais de
              uma resposta, considerando que algumas têm mais de uma ocupação.
              Por essa razão, a soma de respostas não corresponde à soma de
              indivíduos.
            </p>
            <p>
              <sup>2</sup> Mestre em Planejamento Urbano e Regional
              (IPPUR/UFRJ), Graduado em Sociologia (Unitau) e Relações
              Internacionais (PUC Minas). Assessor Técnico do Cadastro, na
              função de analista de dados.
            </p>
            <p>
              <sup>3</sup> Doutor em Educação (UFMG), Mestre em Sociologia
              (UFMG), Graduado em Ciências Sociais (UFMG). Assessor Técnico do
              Cadastro, na função de analista de dados.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_ser_atingido_nao_reconhecidos' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Não reconhecidos"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb.vue";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "O trabalho dos atingidos",
          href: "conteudos_tematicos_ser_atingido_trabalho_atingidos",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__mb {
  padding-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
